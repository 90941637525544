import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { addMonths } from "date-fns";

import { extractError } from "../../../../utils";
import {
  useGetCalendarByRange,
  useGetUnsignedDocuments,
} from "../../../../api.hooks";
import { useAppContext } from "../../../../contexts/AppContext";
import { failInvoice, waitInvoice } from "../../../../api";
import { PlanContextState } from "../../../../contexts/PlanContext";
import { useErrorTracking } from "../../../../components/ErrorTrackingProvider";
import { useConfirm } from "../../../../components/ConfirmProvider";
import { useAnalytics } from "../../../../components/AnalyticsProvider";
import { useToast } from "../../../../components/ToastProvider";
import {
  calculatePlanAmount,
  calculatePlanDiscount,
  calculatePlanTax,
  getCurrentScreen,
} from "./PlansPage.utils";
import { changeTimeZone } from "@pushpress/shared-components";
import { SubscriptionPurchaseResponse } from "../../../../graphql/graphql-generated.types";
import {
  PlanDiscountFragment,
  PlanFragment,
  useGetCalendarItemForPlanQuery,
  useGetInvoicePreviewForPlanQuery,
  usePurchaseSubscriptionPlanMutation,
} from "../../PlansPage-generated.hooks";
import { getSubscriptionUuid } from "../../../../utils/paymentUtils";
import usePlansPreSelectedParams from "./usePlansPreSelectedParams";
import usePlansState from "./usePlansState";

interface UsePlansPageOptions {
  plan: PlanFragment;
  planDiscount: PlanDiscountFragment | null;
  sessionId: string;
}

const DOCUMENT_ERROR = "ERROR_FETCHING_DOCUMENT";

export const usePlansPage = (options: UsePlansPageOptions) => {
  const { plan, planDiscount: planDiscountProp, sessionId } = options;

  // custom hooks

  const navigate = useNavigate();
  const { setErrorToast } = useToast();
  const { pathname, search } = useLocation();
  const { t } = useTranslation("plan");
  const { trackMetadata } = useErrorTracking();
  const {
    stripe,
    user,
    baseUrl,
    client,
    disableStripe,
    logout,
    errorMessage,
    setResetPasswordUrl,
  } = useAppContext();
  const showConfirm = useConfirm();
  const analytics = useAnalytics();

  // local hooks

  const { participantUuid, calendarItemUuidParam, startDate } =
    usePlansPreSelectedParams();

  const {
    participant,
    paymentMethodId,
    payment,
    skippedBooking,
    message,
    previousScreen,
    participantType,
    participantTypeVisible,
    paymentSuccessVisible,
    bookingSuccessVisible,
    documentsSuccessVisible,
    bookedCalendarItemByUuid,
    paying,
    paymentMethodErrors,
    calendarItem,
    bookingSuccessActions,
    documentsSuccessActions,
    payingActions,
    paymentSuccessActions,
    bookingError,
    bookingErrorMessage,
    billing,
    paymentType,
    setParticipant,
    setPaymentMethodId,
    setPayment,
    setSkippedBooking,
    setMessage,
    setPreviousScreen,
    setParticipantType,
    setParticipantTypeVisible,
    setPaymentMethodErrors,
    setCalendarItem,
    setBookedCalendarItemByUuid,
    resetState,
    setBookingError,
    setBookingErrorMessage,
    setBilling,
    setPaymentType,
  } = usePlansState({
    sessionId,
    participantUuid,
    user: user!,
  });

  // refs

  const today = useRef(changeTimeZone(new Date(), client.timezone));
  const calendarEndDate = useRef(addMonths(today.current, 2));

  // query and mutations

  useGetCalendarByRange(
    today.current.getTime(),
    calendarEndDate.current.getTime(),
    plan,
    client.timezone,
    {
      enabled: plan?.landingPageRedirectTo === "reservation",
    },
  );

  useGetCalendarItemForPlanQuery({
    variables: {
      clientUuid: client.uuid,
      uuid: calendarItemUuidParam!,
    },
    skip: !calendarItemUuidParam,
    onCompleted(data) {
      setCalendarItem(data.getCalendarItem);
    },
  });

  const { data: { data: documents = [] } = {}, refetch: refetchDocuments } =
    useGetUnsignedDocuments(
      participant?.userUuid,
      {
        planId: plan.uuid as string,
      },
      {
        enabled: false,
      },
    );

  const [purchaseMutation] = usePurchaseSubscriptionPlanMutation();

  // memo and callbacks

  const planDiscount = useMemo(() => {
    if (!plan || !planDiscountProp) {
      return undefined;
    }
    return planDiscountProp.active ? planDiscountProp : undefined;
  }, [plan, planDiscountProp]);

  const { data: invoicePreviewData } = useGetInvoicePreviewForPlanQuery({
    variables: {
      // @ts-expect-error TODO: fix this
      getInvoicePreviewForPlanInput: {
        billingUuid: paymentMethodId,
        planUuid: String(plan.uuid),
        discountUuid: planDiscount
          ? String(planDiscount.discountUuid)
          : undefined,
      },
    },
  });

  const invoicePreview = invoicePreviewData?.getInvoicePreviewForPlan;

  const activeScreen = useMemo(() => {
    return getCurrentScreen(pathname);
  }, [pathname]);

  const goToScreen = useCallback(
    (screen: PlanScreen) => {
      setPreviousScreen(activeScreen);
      navigate(`${baseUrl}/${screen}`);
    },
    [activeScreen, baseUrl, navigate, setPreviousScreen],
  );

  const bookAnotherSession = useCallback(() => {
    goToScreen("booking");
  }, [goToScreen]);

  const addNewParticipant = useCallback(() => {
    const userName = `${user?.firstName} ${user?.lastName}`;
    showConfirm({
      title: t("confirmation.signUpSomeoneElseTitle"),
      message: t("confirmation.signUpSomeoneElseMessage", { userName }),
      confirmText: t("confirmation.continue"),
      onConfirm: () => {
        resetState();
        setParticipantTypeVisible(false);
        setParticipantType("other");
        goToScreen("participant");
        analytics.trackEvent("button.plus_someone_else", {}, true);
      },
    });
  }, [
    analytics,
    goToScreen,
    resetState,
    setParticipantType,
    setParticipantTypeVisible,
    showConfirm,
    t,
    user?.firstName,
    user?.lastName,
  ]);

  const trackPurchaseEvent = useCallback(
    (data: { payment_type: string }) => {
      analytics.trackEvent("payment_complete", data, true);
    },
    [analytics],
  );

  const addBookedCalendarItem = useCallback(
    (calendarItem: CalendarItem) => {
      setBookedCalendarItemByUuid((prev) => ({
        ...prev,
        [calendarItem.uuid]: calendarItem,
      }));
    },
    [setBookedCalendarItemByUuid],
  );

  const purchasePlan = useCallback(
    async (
      billing: string,
      paymentType: PaymentMethodType | "free",
      calendarItemId?: string,
    ) => {
      // store the state to use them again in case of errors
      setBilling(billing);
      setPaymentType(paymentType);

      const finish = async (result: SubscriptionPurchaseResponse) => {
        try {
          setPayment(result);
          await refetchDocuments();
        } catch (err) {
          console.warn(err);
          throw DOCUMENT_ERROR;
        } finally {
          paymentSuccessActions.on();
          trackPurchaseEvent({
            payment_type: paymentType,
          });
        }
      };

      const confirmPaymentOnStripe = async (
        result: SubscriptionPurchaseResponse,
      ) => {
        if ("stripeActionRequired" in result) {
          const { error } = await stripe!.confirmCardPayment(
            result.stripePaymentIntentClientSecret,
          );
          if (error) {
            await failInvoice(result.invoiceUuid, { onSession: true });
            throw error;
          }
          await waitInvoice(result.invoiceUuid, 8);
        }
      };

      const saveBookingState = async () => {
        if (calendarItem) {
          addBookedCalendarItem(calendarItem);
        }
      };

      const showToastError = (result: SubscriptionPurchaseResponse) => {
        if ("message" in result) {
          const { message } = result;
          setErrorToast({ message });
        }
      };

      const showBookingErrorDialog = (result: SubscriptionPurchaseResponse) => {
        if ("message" in result) {
          setBookingError(true);
          setBookingErrorMessage(result.message);
        }
      };

      // input variables
      const userUuid = participant?.userUuid!;
      const subscriptionStart = startDate
        ? moment(startDate).utc().format("YYYY-MM-DD")
        : null;
      const parentUuid =
        user?.userUuid !== participant?.userUuid ? user?.userUuid : null;
      const discount = planDiscount?.slug;

      try {
        payingActions.on();
        const data = await purchaseMutation({
          variables: {
            input: {
              userUuid,
              billing,
              planUuid: plan?.uuid as string,
              subscriptionStart,
              onSession: true,
              calendarItemId: calendarItemId || null,
              parentUuid: parentUuid || null,
              discount: discount || null,
            },
          },
        });
        const result = data.data
          ?.purchaseSubscriptionPlan! as SubscriptionPurchaseResponse;
        switch (result.__typename) {
          case "SubscriptionPurchaseResult": {
            await finish(result);
            break;
          }
          case "SubscriptionPurchaseRequiresActionResult": {
            await confirmPaymentOnStripe(result);
            await finish(result);
            break;
          }
          case "SubscriptionPurchaseFailedResult": {
            showToastError(result);
            break;
          }
          case "SubscriptionPurchaseRegistrationResult": {
            saveBookingState();
            await finish(result);
            break;
          }
          case "SubscriptionPurchaseRegistrationRequiresActionResult": {
            await confirmPaymentOnStripe(result);
            saveBookingState();
            await finish(result);
            break;
          }
          case "SubscriptionPurchaseRegistrationFailedResult": {
            showBookingErrorDialog(result);
            break;
          }
          default: {
            console.log(`Not expected typename: ${result.__typename}`);
          }
        }
        return result;
      } catch (e) {
        // We only wanna show a payment error when it's not a document error
        if (e !== DOCUMENT_ERROR) {
          setPaymentMethodErrors((prev) => ({
            ...prev,
            [paymentMethodId!]: true,
          }));
        }

        setErrorToast({
          message: extractError(e),
        });
      } finally {
        payingActions.off();
      }
    },
    [
      addBookedCalendarItem,
      calendarItem,
      participant?.userUuid,
      payingActions,
      paymentMethodId,
      paymentSuccessActions,
      plan?.uuid,
      planDiscount?.slug,
      purchaseMutation,
      refetchDocuments,
      setBilling,
      setBookingError,
      setBookingErrorMessage,
      setErrorToast,
      setPayment,
      setPaymentMethodErrors,
      setPaymentType,
      startDate,
      stripe,
      trackPurchaseEvent,
      user?.userUuid,
    ],
  );

  const purchasePlanWithoutBooking = useCallback(async () => {
    await purchasePlan(billing!, paymentType!);
    setCalendarItem(undefined);
    setBookingError(false);
  }, [billing, paymentType, purchasePlan, setBookingError, setCalendarItem]);

  const retryPaymentMethod = useCallback(() => {
    setPaymentMethodErrors((prev) => ({
      ...prev,
      [paymentMethodId!]: false,
    }));
  }, [paymentMethodId, setPaymentMethodErrors]);

  const showMessageScreen = useCallback(
    (text: string) => {
      setMessage(text);
      goToScreen("message");
    },
    [goToScreen, setMessage],
  );

  const nextPaymentScreen = useMemo(() => {
    const hasDocumentsToSign = documents.length > 0;
    if (hasDocumentsToSign) {
      return "documents";
    }
    if (calendarItem) {
      return "confirmation";
    }
    const landingPageRedirectTo = plan?.landingPageRedirectTo;
    if (landingPageRedirectTo === "confirmation") {
      return "confirmation";
    } else {
      return "booking";
    }
  }, [calendarItem, documents.length, plan?.landingPageRedirectTo]);

  const goToAfterPayment = useCallback(() => {
    goToScreen(nextPaymentScreen);
    paymentSuccessActions.off();
  }, [goToScreen, nextPaymentScreen, paymentSuccessActions]);

  const amountDue = useMemo(() => {
    if (invoicePreview) {
      return invoicePreview.total;
    }

    return calculatePlanAmount(plan, planDiscount);
  }, [invoicePreview, plan, planDiscount]);

  const amountTax = useMemo(() => {
    return calculatePlanTax(plan, planDiscount);
  }, [plan, planDiscount]);

  const discount = useMemo(() => {
    return calculatePlanDiscount(plan, planDiscount);
  }, [plan, planDiscount]);

  const isFree = plan ? amountDue <= 0 : false;

  const isLinkedAccount = !!Number(user?.parentUserId);

  const planInvalidMessage = useMemo(() => {
    if (errorMessage) {
      return {
        message: errorMessage,
      };
    }
    if (!plan.active) {
      return {
        message: t("message.planIsInactive"),
      };
    }
    if (plan.limitQuantity && plan.quantityAvailable <= 0) {
      return {
        message: t("message.planIsSoldOut"),
      };
    }
    if (!isFree && !disableStripe && !client.stripeUserId) {
      return {
        title: t("message.noStripeTitle"),
        message: t("message.noStripeMessage", { gymName: client.company }),
      };
    }
    if (message) {
      return {
        message,
      };
    }
    if (startDate) {
      const startMoment = moment(startDate).tz(client.timezone);
      const today = moment().tz(client.timezone);
      if (!startMoment.isValid()) {
        return {
          message: t("message.startDateIsInvalid"),
        };
      }
      if (startMoment.clone().add(1, "d").isBefore(today)) {
        return {
          message: t("message.startDateHasPassed"),
        };
      }
      if (startMoment.isAfter(today.clone().add(1, "y"))) {
        const futureStartDate = startMoment
          .subtract(1, "y")
          .add(1, "d")
          .format("MM/DD/YYYY");
        return {
          message: t("message.startDateNotAvailable", {
            date: futureStartDate,
          }),
        };
      }
    }
  }, [
    client.company,
    client.stripeUserId,
    client.timezone,
    disableStripe,
    errorMessage,
    isFree,
    message,
    plan.active,
    plan.limitQuantity,
    plan.quantityAvailable,
    startDate,
    t,
  ]);

  const redirectTo = useMemo(() => {
    const messagePath = `${baseUrl}/message`;
    const loginPath = `${baseUrl}/login`;
    const participantPath = `${baseUrl}/participant`;
    let result = undefined;
    if (planInvalidMessage) {
      result = messagePath;
    } else if (pathname.endsWith("/reset-password")) {
      result = undefined;
    } else if (!user) {
      result = loginPath;
    } else if (pathname === baseUrl) {
      result = loginPath;
    } else if (pathname === loginPath) {
      result = participantPath;
    }
    if (result === pathname) {
      result = undefined;
    }
    return result;
  }, [baseUrl, pathname, planInvalidMessage, user]);

  const subscriptionUuid = useMemo(() => {
    return getSubscriptionUuid(payment);
  }, [payment]);

  // side effects

  useEffect(() => {
    if (isLinkedAccount) {
      setParticipant(user || undefined);
      setParticipantType("me");
    }
  }, [user, isLinkedAccount, setParticipant, setParticipantType]);

  useEffect(() => {
    if (!user) {
      resetState();
    }
  }, [user, resetState]);

  useEffect(() => {
    if (pathname === baseUrl) {
      resetState();
      logout();
    }
  }, [user, resetState, pathname, baseUrl, logout]);

  useEffect(() => {
    trackMetadata("Plan", {
      uuid: plan.uuid,
      name: plan.name,
      discountSlug: planDiscount?.slug,
      isFree,
      startDate,
    });
  }, [isFree, plan, trackMetadata, startDate, planDiscount?.slug]);

  /**
   * set the reset password url be used inside the auth screen
   */
  useEffect(() => {
    const params = new URLSearchParams(search);
    if (calendarItem?.uuid) {
      params.set("calendar-item", calendarItem?.uuid);
    }
    const query = params.toString() ? `?${params.toString()}` : "";
    const url = `${baseUrl}/reset-password${query}`;
    setResetPasswordUrl(url);
  }, [setResetPasswordUrl, search, baseUrl, calendarItem?.uuid]);

  const transactionFee = useMemo(
    () => invoicePreview?.fees?.find((fee) => fee.code === "transactionFee"),
    [invoicePreview?.fees],
  );

  // return

  const planContext: PlanContextState = {
    plan,
    planDiscount,
    invoicePreview,
    transactionFee,
    amountDue,
    amountTax,
    discount,
    participant,
    startDate,
    paymentMethodId,
    isFree,
    skippedBooking,
    planInvalidMessage,
    isLinkedAccount,
    previousScreen,
    participantType,
    participantTypeVisible,
    paymentSuccessVisible,
    bookingSuccessVisible,
    documentsSuccessVisible,
    bookedCalendarItemByUuid,
    paymentSuccessActions,
    bookingSuccessActions,
    documentsSuccessActions,
    paying,
    paymentMethodErrors,
    nextPaymentScreen,
    calendarItem,
    bookingError,
    subscriptionUuid,
    setParticipantType,
    setPayment,
    setParticipant,
    goToScreen,
    addNewParticipant,
    setPaymentMethodId,
    setSkippedBooking,
    purchasePlan,
    showMessageScreen,
    bookAnotherSession,
    addBookedCalendarItem,
    retryPaymentMethod,
    goToAfterPayment,
    today: today.current,
    calendarEndDate: calendarEndDate.current,
  };

  return {
    planContext,
    baseUrl,
    redirectTo,
    bookingError,
    bookingErrorMessage,
    purchasePlanWithoutBooking,
  };
};
