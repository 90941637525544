import { GetServerSideProps } from "next";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { executeGraphQl } from "../../../../graphql";
import { AppPageProps } from "../../../_app.page";
import { getAppProps } from "../../../../utils/server";
import PlanContext from "../../../../contexts/PlanContext";
import { useAppContext } from "../../../../contexts/AppContext";
import NotFoundPage from "../../../../components/NotFoundPage";
import PaymentSuccessDialog from "../../../../components/PaymentSuccessDialog";
import { useAnalytics } from "../../../../components/AnalyticsProvider";
import BookingErrorDialog from "../../../../components/BookingErrorDialog";
import Authentication from "../../../../screens/auth/Authentication";
import ResetPassword from "../../../../screens/auth/ResetPassword";
import Participant from "../../../../screens/plans/Participant";
import Payment from "../../../../screens/plans/Payment";
import Booking from "../../../../screens/plans/Booking";
import Confirmation from "../../../../screens/plans/Confirmation";
import Message from "../../../../screens/plans/Message";
import Documents from "../../../../screens/plans/Documents";
import { getQueryOptions } from "./PlansPage.utils";
import { usePlansPage } from "./PlansPage.hooks";
import {
  GetDiscountsDocument,
  GetDiscountsQuery,
  GetDiscountsQueryVariables,
  GetSubscriptionPlanDocument,
  GetSubscriptionPlanQueryVariables,
  PlanDiscountFragment,
  PlanFragment,
} from "../../PlansPage-generated.hooks";
import { GetSubscriptionPlanQuery } from "../../../../graphql/graphql-generated.types";

interface PlansPageProps {
  plan: PlanFragment;
  planDiscount: PlanDiscountFragment | null;
  sessionId: string;
  referredFromStaffKiosk: boolean;
}

const PlansPage = (props: PlansPageProps) => {
  const { plan, planDiscount, sessionId, referredFromStaffKiosk } = props;
  const [initialLoad, setInitialLoad] = useState(true);

  const { logout } = useAppContext();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const {
    planContext,
    baseUrl,
    redirectTo,
    bookingError,
    bookingErrorMessage,
    purchasePlanWithoutBooking,
  } = usePlansPage({
    sessionId,
    plan,
    planDiscount,
  });

  const {
    paymentSuccessVisible,
    amountDue,
    nextPaymentScreen,
    calendarItem,
    paying,
    goToAfterPayment,
  } = planContext;

  // Track event only if a user arrived at this
  // LP from the Staff App Kiosk QR code
  useEffect(() => {
    const isSegmentInitialized =
      analytics.initializedPlugins.includes("segment");

    if (initialLoad && referredFromStaffKiosk && isSegmentInitialized) {
      analytics.trackEvent(
        "kiosk_referral_opened",
        {
          uuid: plan.uuid,
          price: amountDue,
        },
        true,
      );
      setInitialLoad(false);
    }
  }, [
    amountDue,
    analytics,
    analytics.initializedPlugins,
    initialLoad,
    plan.uuid,
    referredFromStaffKiosk,
  ]);

  if (redirectTo) {
    navigate(redirectTo, { replace: true });
    return null;
  }

  return (
    <PlanContext.Provider value={planContext}>
      <Routes>
        <Route path={`${baseUrl}/*`}>
          <Route path="message" element={<Message />} />
          <Route path="login" element={<Authentication />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="participant" element={<Participant />} />
          <Route path="payment" element={<Payment />} />
          <Route path="documents" element={<Documents />} />
          <Route path="booking" element={<Booking />} />
          <Route path="confirmation" element={<Confirmation />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <PaymentSuccessDialog
        visible={paymentSuccessVisible}
        name={plan.name}
        price={amountDue}
        nextScreen={nextPaymentScreen}
        referredFromStaffKiosk={referredFromStaffKiosk}
        entityUuid={plan.uuid as string}
        // @ts-expect-error TODO: fix this
        calendarItem={calendarItem}
        onNextClick={goToAfterPayment}
      />
      <BookingErrorDialog
        visible={bookingError}
        message={bookingErrorMessage}
        paying={paying}
        logout={logout}
        purchasePlanWithoutBooking={purchasePlanWithoutBooking}
      />
    </PlanContext.Provider>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const appProps = await getAppProps(context);
  const referredFromStaffKiosk = context.query?.origin === "kiosk";

  if ("redirect" in appProps) {
    return appProps;
  }

  // return if there is an error
  if ("error" in appProps) {
    return {
      props: appProps,
    };
  }

  // get the plan
  const { planId, options } = context.query;
  let plan: PlanFragment;
  try {
    const planResult = await executeGraphQl<
      GetSubscriptionPlanQuery,
      GetSubscriptionPlanQueryVariables
    >(
      GetSubscriptionPlanDocument,
      {
        input: {
          planUuid: planId as string,
        },
      },
      appProps.publicToken,
    );
    plan = planResult.getSubscriptionPlan as PlanFragment;
  } catch (err) {
    const error = `Failed to get plan info for: ${planId}`;
    console.error(error, err);
    return {
      props: {
        error,
      },
    };
  }

  // get the discount
  const { discountSlug } = getQueryOptions(options);

  let planDiscount: PlanDiscountFragment | null = null;
  if (discountSlug) {
    try {
      const discountResult = await executeGraphQl<
        GetDiscountsQuery,
        GetDiscountsQueryVariables
      >(GetDiscountsDocument, {
        input: {
          clientUuid: appProps.client.uuid,
          slug: discountSlug as string,
          active: undefined,
          category: undefined,
          code: undefined,
          discountUuid: undefined,
        },
      });
      planDiscount = (discountResult.getDiscounts?.[0] ||
        null) as PlanDiscountFragment;
    } catch (err) {
      const error = `Failed to get plan info for: ${planId}`;
      console.error(error, err);
      return {
        props: {
          error,
        },
      };
    }
  }

  // redirect if it is not a public plan
  if (!plan.isPublic) {
    return {
      redirect: {
        destination: "/landing/plans",
        permanent: false,
      },
    };
  }

  // create the title and favicon
  const title = `Join ${appProps.client.company} - ${plan.name} | PushPress`;
  const favicon = appProps.client.logoUrl || null;

  // get the session id to save in the session storage
  const sessionId = planId as string;

  // page props
  const plansPageProps: PlansPageProps & AppPageProps = {
    ...appProps,
    title,
    favicon,
    plan,
    planDiscount,
    sessionId,
    referredFromStaffKiosk,
  };
  return {
    props: plansPageProps,
  };
};

export default PlansPage;
